<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template v-if="$permissionAbility(SITE_LIST_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table table-custom-style striped"
        :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Website -->
          <template v-if="props?.column?.field === 'website_format'">
            <div v-if="props?.row?.website">
              <a
                :href="props.row.website"
                target="_blank"
                rel="noopener noreferrer"
                class="font-weight-bold"
              >
                {{ formatWebsite(props?.row?.website) }}
              </a>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Niche -->
          <template v-if="props?.column?.field === 'niche_format'">
            <div v-if="props?.row?.niche">
              <span class="font-weight-bold">{{
                props?.row?.niche?.data?.name
              }}</span>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Seller -->
          <template v-if="props?.column?.field === 'seller_format'">
            <div v-if="props?.row?.seller?.data?.name">
              <span class="font-weight-bold">{{
                props?.row?.seller?.data?.name
              }}</span>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Traffic -->
          <template v-if="props?.column?.field === 'traffic_format'">
            <div v-if="props?.row?.traffic">
              {{ formatNumber(props?.row?.traffic) }}
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Traffic Value Format -->
          <template v-if="props?.column?.field === 'traffic_value_format'">
            <div v-if="props?.row?.traffic_value">
              {{ formatNumber(props?.row?.traffic_value) }}
              {{ props?.row?.traffic_value_currency_detail?.currency }}
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Traffic Value Format -->
          <template v-if="props?.column?.field === 'spam_score_format'">
            <div v-if="props?.row?.spam_score">
              {{ props?.row?.spam_score }} %
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Post Price Format -->
          <template v-if="props?.column?.field === 'post_price_format'">
            <div v-if="props?.row?.post_price">
               {{ props?.row?.post_price }} {{ props?.row?.post_price_currency_detail?.currency }}
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- link_insertion_price_format -->
          <template
            v-if="props?.column?.field === 'link_insertion_price_format'"
          >
            <div v-if="props?.row?.link_insertion_price">
              {{ props?.row?.link_insertion_price }}
              {{ props?.row?.link_insertion_price_currency_detail?.currency }}
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Status -->
          <template v-if="props?.column?.field === 'status_format'">
            <div v-if="props?.row?.status == 'Pending'">
              <b-badge variant="light-primary">
                {{ props?.row?.status }}
              </b-badge>
            </div>

            <div v-else-if="props?.row?.status == 'Approved'">
              <b-badge variant="light-success">
                {{ props?.row?.status }}
              </b-badge>
            </div>

            <div v-else-if="props?.row?.status == 'Soft Reject'">
              <b-badge variant="light-warning">
                {{ props?.row?.status }}
              </b-badge>
            </div>

            <div v-else-if="props?.row?.status == 'Rejected'">
              <b-badge variant="light-danger">
                {{ props?.row?.status }}
              </b-badge>
            </div>

            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <span v-if="props.column.field === 'action'">
            <template v-if="$permissionAbility(SITE_LIST_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="Edit2Icon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Edit"
                  size="16"
                />
              </span>
            </template>

            <template v-if="$permissionAbility(SITE_LIST_DELETE, permissions)">
              <span @click="onDelete(props.row.id)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="TrashIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Delete"
                  size="16"
                />
              </span>
            </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-create-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Site' : 'Create Site'"
      hide-footer
      @hidden="hiddenModal"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="createFormValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- website -->
          <b-form-group
            label="Website"
            label-for="website"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="website"
              vid="website"
              rules="required"
            >
              <b-form-input
                id="website"
                type="text"
                v-model="websiteUrl"
                :state="errors.length > 0 ? false : null"
                placeholder="The website must be a valid URL. (e.g https://www.example.com)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>

            <b-col md="4" lg="4" xs="12">
              <!-- Niche -->
              <b-form-group
                label="Niche"
                label-for="niche"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="niche"
                  vid="niche"
                  rules="required"
                >
                  <v-select
                    id="niche"
                    v-model="selectNicheId"
                    :options="filteredNicheOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Seller -->
              <b-form-group
                label="Seller"
                label-for="seller"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="seller"
                  vid="seller"
                  rules="required"
                >
                  <v-select
                    id="seller"
                    v-model="selectSellerId"
                    :options="filteredSellerOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Project -->
              <b-form-group
                label="Project"
                label-for="project"
              >
                <validation-provider
                  #default="{ errors }"
                  name="project"
                  vid="project"
                >
                  <v-select
                    id="project"
                    v-model="selectProjectId"
                    :options="filteredProjectOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Traffic -->
              <b-form-group label="Traffic" label-for="traffic">
                <validation-provider
                  #default="{ errors }"
                  name="traffic"
                  vid="traffic"
                >
                  <b-form-input
                    id="traffic"
                    type="number"
                    v-model="trafficInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Traffic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Traffic Value -->
              <b-form-group label="Traffic Value" label-for="traffic_value">
                <validation-provider
                  #default="{ errors }"
                  name="traffic_value"
                  vid="traffic_value"
                >
                  <b-form-input
                    id="traffic_value"
                    type="number"
                    v-model="trafficValueInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Traffic Value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Traffic Value Currency-->
              <b-form-group label="Traffic Value Currency" label-for="traffic_value_currency">
                <validation-provider
                  #default="{ errors }"
                  name="traffic_value_currency"
                  vid="traffic_value_currency"
                >
                  <v-select
                    id="project"
                    v-model="trafficValueCurrencyInput"
                    :options="filteredCurrencyOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- spam_score -->
              <b-form-group label="Spam Score" label-for="spam_score">
                <validation-provider
                  #default="{ errors }"
                  name="spam_score"
                  vid="spam_score"
                >
                  <b-form-input
                    id="spam_score"
                    type="number"
                    v-model="spamScoreInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Spam Score"
                    min="0"
                    max="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- post_price -->
              <b-form-group label="Post Price" label-for="post_price">
                <validation-provider
                  #default="{ errors }"
                  name="post_price"
                  vid="post_price"
                >
                  <b-form-input
                    id="post_price"
                    type="number"
                    v-model="postPriceInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Post Price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- post_price_currency-->
              <b-form-group label="Post Price Currency" label-for="post_price_currency">
                <validation-provider
                  #default="{ errors }"
                  name="post_price_currency"
                  vid="post_price_currency"
                >
                  <v-select
                    id="project"
                    v-model="postPriceCurrencyInput"
                    :options="filteredCurrencyOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- link_insertion_price -->
              <b-form-group label="Link Insertion Price" label-for="link_insertion_price">
                <validation-provider
                  #default="{ errors }"
                  name="link_insertion_price"
                  vid="link_insertion_price"
                >
                  <b-form-input
                    id="link_insertion_price"
                    type="number"
                    v-model="linkInsertionPriceInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Link Insertion Price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- link_insertion_price_currency-->
              <b-form-group label="Link Insertion Price Currency" label-for="link_insertion_price_currency">
                <validation-provider
                  #default="{ errors }"
                  name="link_insertion_price_currency"
                  vid="link_insertion_price_currency"
                >
                  <v-select
                    id="project"
                    v-model="linkInsertionPriceCurrencyInput"
                    :options="filteredCurrencyOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- domain_authority -->
              <b-form-group label="Domain Authority" label-for="domain_authority">
                <validation-provider
                  #default="{ errors }"
                  name="domain_authority"
                  vid="domain_authority"
                >
                  <b-form-input
                    id="domain_authority"
                    type="number"
                    v-model="domainAuthorityInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Domain Authority"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- referal_domain -->
              <b-form-group label="Referral Domain" label-for="referal_domain">
                <validation-provider
                  #default="{ errors }"
                  name="referal_domain"
                  vid="referal_domain"
                >
                  <b-form-input
                    id="referal_domain"
                    type="number"
                    v-model="referalDomainInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Referral Domain"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- approved_for -->
              <b-form-group label="Approved For" label-for="approved_for" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="approved_for"
                  vid="approved_for"
                  class="required-label"
                  rules="required"
                >
                  <v-select
                    id="approved_for"
                    v-model="approvedForId"
                    :options="filteredApprovedForOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                    rules="required"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="modelType === 'editModel'">
              <b-col md="12" lg="12" xs="12">
              <!-- Status -->
              <b-form-group
                label="Status"
                label-for="status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  vid="status"
                >
                  <v-select
                    id="status"
                    v-model="selectStatusId"
                    :options="filtererStatusOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            </template>
            

            <b-col md="12" lg="12" xs="12">
              <!-- referal_domain -->
              <b-form-group label="Remarks" label-for="remarks">
                <validation-provider
                  #default="{ errors }"
                  name="remarks"
                  vid="remarks"
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarksInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Remarks"
                    rows="3"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>
  
  <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
  BFormTextarea,

} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import {
  SITE_LIST_ACCESS,
  SITE_LIST_CREATE,
  SITE_LIST_EDIT,
  SITE_LIST_DELETE,
  SITE_LIST_SHOW,
} from "@/helpers/permissionsConstant";
import { filter } from "lodash";
import { ref } from "vue";

export default {
  name: "SiteListView",
  components: {
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormTextarea,

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      SITE_LIST_ACCESS,
      SITE_LIST_CREATE,
      SITE_LIST_EDIT,
      SITE_LIST_DELETE,
      SITE_LIST_SHOW,

      // Permissions
      modelType: "",
      name: "",

      siteListId: "",
      websiteUrl: "",
      selectNicheId: "",
      selectSellerId: "",
      filteredNicheOptions: [],
      filteredSellerOptions: [],
      trafficInput: "",
      trafficValueInput: "",
      trafficValueCurrencyInput: "",
      spamScoreInput: "",
      postPriceInput: "",
      postPriceCurrencyInput: "",
      linkInsertionPriceInput: "",
      linkInsertionPriceCurrencyInput: "",
      domainAuthorityInput: "",
      referalDomainInput: "",
      filteredProjectOptions: [],
      filteredCurrencyOptions: [],
      selectProjectId: "",
      approvedForId: "",
      filteredApprovedForOptions: [
        { id: 0, name: "White Niche" },
        { id: 1, name: "Gray Niche" },

      ],
      selectStatusId: "",
      filtererStatusOptions: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Approved" },
        { id: 2, name: "Rejected" },
        { id: 3, name: "Soft Reject" },
      ],
      remarksInput: "",

     
      //table
      pageLength: 10,
      columns: [
        {
          label: "Website",
          field: "website_format",
          sortable: false,
        },
        {
          label: "Niche",
          field: "niche_format",
          sortable: false,
        },
        {
          label: "Seller",
          field: "seller_format",
          sortable: false,
        },
        {
          label: "Traffic",
          field: "traffic_format",
          sortable: false,
        },
        {
          label: "Traffic Value",
          field: "traffic_value_format",
          sortable: false,
        },
        {
          label: "Spam Score",
          field: "spam_score_format",
          sortable: false,
        },
        {
          label: "Post Price",
          field: "post_price_format",
          sortable: false,
        },
        {
          label: "Link Price",
          field: "link_insertion_price_format",
          sortable: false,
        },
        {
          label: "Status",
          field: "status_format",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDateTime,
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
      await this.getAllCurrencies();
      await this.getAllNiches();
      await this.getAllSellers();
      await this.getAllProjects();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatWebsite(website) {
      const maxLength = 20;
      if (website.length > maxLength) {
        return website.slice(0, maxLength) + "...";
      }
      return website;
    },

    formatNumber(traffic) {
      if (traffic >= 1e9) {
        return (traffic / 1e9).toFixed(1) + "B";
      } else if (traffic >= 1e6) {
        return (traffic / 1e6).toFixed(1) + "M";
      } else if (traffic >= 1e3) {
        return (traffic / 1e3).toFixed(1) + "K";
      }
      return traffic;
    },

    showModal() {
      this.$bvModal.show("modal-create-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-create-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.siteListId = "";

      this.websiteUrl = "";
      this.selectNicheId = "";
      this.selectSellerId = "";
      this.selectProjectId = "";
      this.trafficInput = "";
      this.trafficValueInput = "";
      this.trafficValueCurrencyInput = "";
      this.spamScoreInput = "";
      this.postPriceInput = "";
      this.postPriceCurrencyInput = "";
      this.linkInsertionPriceInput = "";
      this.linkInsertionPriceCurrencyInput = "";
      this.domainAuthorityInput = "";
      this.referalDomainInput = "";
      this.approvedForId = "";
      this.selectStatusId = "";
      this.remarksInput = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.siteListId = value.id;

      this.websiteUrl = value.website;
      this.selectNicheId = value.nich_id;
      this.selectSellerId = value.seller_id;
      this.selectProjectId = value.project_id;
      this.trafficInput = value.traffic;
      this.trafficValueInput = value.traffic_value;
      this.trafficValueCurrencyInput = value.traffic_value_currency;
      this.spamScoreInput = value.spam_score;
      this.postPriceInput = value.post_price;
      this.postPriceCurrencyInput = value.post_price_currency;
      this.linkInsertionPriceInput = value.link_insertion_price;
      this.linkInsertionPriceCurrencyInput = value.link_insertion_price_currency;
      this.domainAuthorityInput = value.domain_authority;
      this.referalDomainInput = value.referal_domain;
      this.approvedForId = value.approved_id;
      this.selectStatusId = value.status_id;
      this.remarksInput = value.remarks;

      this.showModal();
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/site-lists/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Site List Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getSiteListItems(params) {
      return await this.$api.get("api/site-lists?include=seller,niche", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    async getAllCurrencies() {
      try {
        const response = await this.$api.get("api/currencies/all");

        this.filteredCurrencyOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
        
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllNiches() {
      try {
        const response = await this.$api.get("api/outreach/niche/all");

        this.filteredNicheOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllSellers() {
      try {
        const response = await this.$api.get("api/outreach/seller/all");

        this.filteredSellerOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllProjects() {
      try {
        const response = await this.$api.get("api/projects/approve");

        this.filteredProjectOptions = (response?.data?.data || []).map((item) => {
          let name = item.lead.title;
          return {
            name,
            id: item.id,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        const siteListData = await this.getSiteListItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = siteListData?.data?.data;
        const meta = siteListData?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.createFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.createFormValidation.reset();
            if (this.modelType == "editModel") {
              this.isSubmitLoading = true;
              await this.$api.put(
                `/api/site-lists/${this.siteListId}`,
                {
                  website: this.websiteUrl,
                  nich_id: this.selectNicheId,
                  seller_id: this.selectSellerId,
                  project_id: this.selectProjectId,
                  traffic: this.trafficInput,
                  traffic_value: this.trafficValueInput,
                  traffic_value_currency: this.trafficValueCurrencyInput,
                  spam_score: this.spamScoreInput,
                  post_price: this.postPriceInput,
                  post_price_currency: this.postPriceCurrencyInput,
                  link_insertion_price: this.linkInsertionPriceInput,
                  link_insertion_price_currency: this.linkInsertionPriceCurrencyInput,
                  domain_authority: this.domainAuthorityInput,
                  referal_domain: this.referalDomainInput,
                  approved_for: this.approvedForId,
                  status: this.selectStatusId,
                  remarks: this.remarksInput,
                }
              );
              this.isSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Outreach Seller Successfully Updated",
                },
              });
            } else {
              this.isSubmitLoading = true;

              const pendingStatus = this.filtererStatusOptions.find(option => option.id === 0);

              await this.$api.post("/api/site-lists", {
                website: this.websiteUrl,
                nich_id: this.selectNicheId,
                seller_id: this.selectSellerId,
                project_id: this.selectProjectId,
                traffic: this.trafficInput,
                traffic_value: this.trafficValueInput,
                traffic_value_currency: this.trafficValueCurrencyInput,
                spam_score: this.spamScoreInput,
                post_price: this.postPriceInput,
                post_price_currency: this.postPriceCurrencyInput,
                link_insertion_price: this.linkInsertionPriceInput,
                link_insertion_price_currency: this.linkInsertionPriceCurrencyInput,
                domain_authority: this.domainAuthorityInput,
                referal_domain: this.referalDomainInput,
                approved_for: this.approvedForId,
                status: pendingStatus.id,
                remarks: this.remarksInput,
              });
              this.isSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Outreach Seller Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
  
  